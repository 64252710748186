module.exports = {
    i18n: {
        defaultLocale: 'en',
        locales: ['en']
    },
    react: {
        useSuspense: false,
        wait: true
    }
};
