import axios from 'axios';
import { getAuth } from './identity.service';

export const postWithOutAuth = (url, entity) => new Promise((resolve, _reject) => {

    const headers = {
        'content-type': 'application/json',
        'x-platform': 'dbdaddy-web'
    };

    axios
        .post(url, entity, {headers})
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, message: ex.message });
        });
});

export const postWithAuth = (url, entity) => new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth.token,
        'x-platform': 'dbdaddy-web'
    };

  axios
    .post(url, entity, { headers })
    .then((response) => {
      if (response && response.data) {
        resolve({ status: true, data: response.data });
      }
    })
    .catch((ex) => {
      resolve({ status: false, message: ex.message });
    });
});

export const getWithOutAuth = (url) => new Promise((resolve, _reject) => {

    const headers = {
        'x-platform': 'dbdaddy-web'
    };

    axios
        .get(url, {headers})
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, error: ex.message });
        });
});

export const getWithAuth = (url, token = '') => {
    const headers = {
        'content-type': 'application/json',
        'x-access-token': token,
        'x-platform': 'dbdaddy-web'
    };

  return new Promise((resolve, _reject) => {
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const getWithAuthWithToken = (url) => {
  const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        'x-platform': 'dbdaddy-web'
    };

  return new Promise((resolve, _reject) => {
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const deleteWithAuth = (url, entity) => new Promise((resolve, _reject) => {
  const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        'x-platform': 'dbdaddy-web'
    };

  axios
    .delete(url, {
      data: entity,
      headers
    })
    .then((response) => {
      if (response && response.data) {
        resolve({ status: true, data: response.data });
      }
    })
    .catch((ex) => {
      resolve({ status: false, message: ex.message });
    });
});

export const deleteWithOutAuth = (url, entity) => new Promise((resolve, _reject) => {
    const headers = {
        'content-type': 'application/json',
        'x-platform': 'dbdaddy-web'
    };
    axios
        .delete(url, {
            data: entity
        }, {headers})
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, message: ex.message });
        });
});

export const putWithAuth = (url, data) => {
  const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        'x-platform': 'dbdaddy-web'
    };

  return new Promise((resolve, _reject) => {
    axios
      .put(url, data, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};
