const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

// project
export const FILE_UPLOAD = (projectId, dbml) => UrlParamsReplace('/dbdiagram/:projectId', { projectId, dbml });
export const CREATE_PROJECT = UrlParamsReplace('/dbdiagram/project/create')
export const GET_PROJECT = (projectId) => UrlParamsReplace('/dbdiagram/:projectId', { projectId })
export const SET_DB_CONNECTION = (projectId, dbInfo) => UrlParamsReplace('/dbdiagram/:projectId/db-connection', { projectId, dbInfo });
export const GET_DBML_FILE = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/get', { projectId });
export const SAVE_DBML_FILE = (projectId, dbml) => UrlParamsReplace('/dbdiagram/:projectId/save', { projectId, dbml });
export const UPDATE_PROJECT_NAME = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/update-project-name', { projectId });
export const UPDATE_PROJECT_SHARE_STATUS = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/update-project-share-status', { projectId })



// table
export const GET_TABLES = (projectId, offset, limit, search) => UrlParamsReplace('/dbdiagram/:projectId/tables?offset=:offset&limit=:limit&search=:search',
  { offset, limit, search, projectId });
export const GET_TABLE_DETAILS = (projectId, search) => UrlParamsReplace('/dbdiagram/:projectId/table-details?search=:search', { projectId, search });
export const GET_TABLE_RELATIONS = (projectId, tableName) => UrlParamsReplace('/dbdiagram/:projectId/table-relations?tableName=:tableName', { projectId, tableName });
export const DELETE_TABLES = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/tables', { projectId });

// tag
export const CREATE_TAG = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/tag', { projectId });
export const GET_TAGS = (projectId, offset, limit, search) => UrlParamsReplace('/dbdiagram/:projectId/tags?offset=:offset&limit=:limit&search=:search',
  { offset, limit, search, projectId });
export const UPDATE_TAG_NAME = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/tag/update', { projectId });
export const DELETE_TAG_NAME = (projectId) => UrlParamsReplace('/dbdiagram/:projectId/tag/delete', { projectId });

export const GET_TAG_DETAILS = (projectId, tagName) => UrlParamsReplace('/dbdiagram/:projectId/tag-details?tagName=:tagName', { projectId, tagName });
export const GET_MULTPLE_TAG_DETAILS = (projectId, tagArray) => UrlParamsReplace('/dbdiagram/:projectId/multiple-tags-details?tagArray=:tagArray', { projectId, tagArray });

// table-tag
export const ADD_TABLE_TO_TAG = (projectId, details) => UrlParamsReplace('/dbdiagram/:projectId/table-tag', { projectId, details });
export const GET_TABLE_TAGS = (projectId, tableName) => UrlParamsReplace('/dbdiagram/:projectId/table-tags?tableName=:tableName', { projectId, tableName });

// user
export const GET_USER_FOR_AUTHENTICATION = UrlParamsReplace('/user-authentication');
export const DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL = UrlParamsReplace("/dump-logs/aws");
export const SIGN_IN_WITH_EMAIL = UrlParamsReplace('/dbdiagram/otp')
export const RESEND_OTP = UrlParamsReplace('/dbdiagram/resend-otp')
export const VERIFY_OTP = UrlParamsReplace('/dbdiagram/verify-otp')
export const UPDATE_USER = UrlParamsReplace('/user/update');
export const UPDATE_LAST_VISITED = UrlParamsReplace('/user/visited')
// Subscription
export const UPDATE_SUBSCRIPTION_STATUS = (email) => UrlParamsReplace('/dbdiagram/subscription-status/:email', { email })

