import Anchor from "../atoms/Anchor";
import List from "../atoms/List";
import React from "react";

const SubMenu = ({ classForSubmenu, SubMenu }) => {
  return (
    <div className={`${classForSubmenu}`}>
      <ul>
        {SubMenu &&
          SubMenu.map(
            ({
              anchorId,
              classForList,
              behaviour,
              className,
              target,
              href,
              name,
              id,
            }) => (
              <List key={id} className={`text-sm font-normal px-2 py-2.5 text-gray-700 bg-white hover:bg-primary-50 hover:text-purple-700 ${classForList}`} onClick={behaviour}>
                <Anchor
                  id={anchorId}
                  className={className}
                  target={target && target}
                  href={href && href}
                >
                  {name}
                </Anchor>
              </List>
            )
          )}
      </ul>
    </div>
  );
};

export default SubMenu;
