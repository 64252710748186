import Image from 'next/image'
import React from 'react'
import Text from '../atoms/Text'
import PropTypes from 'prop-types';
const Alert = ({className,alertText,basePath = process.env.NEXT_PUBLIC_BASE_PATH}) => {
  return (
    <div className="w-[400px] py-1 flex items-center px-4">
      <span className=''><Image src={`${basePath}/images/alert-circle-4.svg`} width={12} height={12}/></span>
      <Text className='text-gray-400 ml-1 text-xs font-medium leading-4'>{alertText}</Text>
    </div>
  )
}
Alert.propTypes={
  className:PropTypes.string,
  alertText:PropTypes.string.isRequired,
  basePath:PropTypes.string
}
export default Alert