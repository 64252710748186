import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import editorSliceReducer from "./reducers/editor";
import loaderReducer from './reducers/loader';
import projectReducer from './reducers/project';
import tagSliceReducer from "./reducers/tags";

const reducers = combineReducers({ loader: loaderReducer, tags: tagSliceReducer, editor: editorSliceReducer, project: projectReducer });

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['tags', 'project']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer
});

export default store;
