import Text from '../atoms/Text'
import Image from 'next/image'
import React, { useRef } from 'react'
import Button from '../atoms/Button'

const FileUpload = ({basePath = process.env.NEXT_PUBLIC_BASE_PATH,handleDragOver,handleOnDrop,className,handleFileSelect,handleDrapLeave}) => {
  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  };
  return (
    <div className={`p-5 ${className}`}>
        <div className='border-2 border-gray-200 border-dashed rounded flex flex-col items-center p-9 gap-y-2 transition duration-200' onDragOver={handleDragOver} onDrop={handleOnDrop} onDragLeave={handleDrapLeave} id='filedragondrop'>
           <Image src={`${basePath}/images/upload-cloud.svg`} width='78' height='78' alt='upload-cloud'/>
           <Text className='font-normal text-base text-gray-700'>Drag and drop</Text>
           <div className='text-center space-y-2'>
             <input type="file" className="hidden" ref={fileInput} onInput={(e) => handleFileSelect(e)}/>
             <Text className='font-normal text-[11px] text-gray-500'>OR</Text>
             <Button varient='withoutHover' iconShow={false} handleClick={selectFile}>Browse files</Button>
           </div>
        </div>
    </div>
  )
}

export default FileUpload
