import React from 'react'
import PropTypes from 'prop-types';
const Heading = ({className, children,  type='h1', ...properties}) => {



 const headingVarients={
   h1:'h1',
   h2:'h2',
   h3:'h3',
   h4:'h4',
   h5:'h5',
   h6:'h6'
 }

 const Type = headingVarients[type] ? headingVarients[type] :'h1'
  return (
   <>
     <Type className={`${className}`} {...properties}>
      {children}
     </Type>
   </>
  )
}

Heading.propTypes = {
   className: PropTypes.string,
   children:PropTypes.node.isRequired,
   type:PropTypes.string,
 };

export default Heading


