import { getWithOutAuth, postWithAuth, postWithOutAuth } from '@/services/http.service';
import {
  CREATE_PROJECT, FILE_UPLOAD, GET_DBML_FILE, GET_PROJECT, SAVE_DBML_FILE, SET_DB_CONNECTION, UPDATE_PROJECT_NAME, UPDATE_PROJECT_SHARE_STATUS
} from '@/services/url.service';

export const uploadFileApi = (projectId, dbml, fileType) => postWithOutAuth(FILE_UPLOAD(projectId), { dbml, fileType });
export const createProject = (token) => postWithAuth(CREATE_PROJECT, token)
export const getProject = (projectId) => getWithOutAuth(GET_PROJECT(projectId))
export const setDbConnection = (projectId, dbInfo) => postWithOutAuth(SET_DB_CONNECTION(projectId), dbInfo);
export const getDbmlFileApi = (projectId) => getWithOutAuth(GET_DBML_FILE(projectId));
export const saveDbmlApi = (projectId, dbml) => postWithOutAuth(SAVE_DBML_FILE(projectId), { dbml });
export const updateProjectNameApi = (projectId, fileName) => postWithOutAuth(UPDATE_PROJECT_NAME(projectId), { fileName });
export const updateProjectShareStatusApi = (projectId, isPublic) => postWithOutAuth(UPDATE_PROJECT_SHARE_STATUS(projectId), { isPublic })
