import Image from "next/image";
import PropTypes from "prop-types";

const Button = ({
  type,
  varient,
  className,
  children,
  handleClick,
  iconShow = true,
  isLoading = false,
  rotate = false,
  imgSrc,
  imgAlt,
  imgWidth = 16,
  imgHeight = 16,
  classForIcon,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  ...property
}) => {
  const buttonStyle = {
    normalBtn:
      "bg-purple-700 border font-semibold text-xs rounded-lg flex items-center py-2.5 px-4 text-white hover:bg-white hover:border-purple-700 hover:text-purple-700  transition duration-300",
    secoundryBtn:
      "bg-purple-700 py-3 px-6 rounded-lg text-white font-semibold text-sm leading-6 flex justify-center items-center",
    ghostBtn:
      "flex items-center transition duration-300 text-sm font-medium text-gray-500 py-1 px-2 hover:bg-gray-50 rounded-md",
    transparent:
      "border border-purple-200 bg-white rounded-md text-purple-700 text-sm font-semibold px-2 py-1 transition duration-500 hover:bg-primary-50",
      
    add: "w-6 h-6 rounded-4 flex justify-center items-center",
    withoutHover:'text-white text-sm font-semibold py-2 px-6 rounded-md bg-primary-900 cursor-pointer'
  };

  return (
    <button
      className={`${buttonStyle[varient]} ${className}`}
      onClick={(e) => handleClick && handleClick(e)}
      {...property}
    >
      {iconShow && (
        <span
          className={`transform duration-500 relative ${
            rotate ? "-rotate-180" : "rotate-0"
          } ${classForIcon}`}
        >
          <Image
            src={`${basePath}${imgSrc}`}
            alt={`${imgAlt}`}
            width={imgWidth}
            height={imgHeight}
          />
        </span>
      )}
      {isLoading ? (
        <>
          <div className="w-5 h-5 animate-spin ">
            <Image
              src={`${basePath}/images/loader.svg`}
              layout="fill"
              alt="loader"
            />
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  varient: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  iconShow: PropTypes.bool,
  isLoading: PropTypes.bool,
  rotate: PropTypes.bool,
  basePath: PropTypes.string,
};

export default Button;
