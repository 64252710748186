import { handleApiResponse } from "@/services/helper-function.service"
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import Text from '../atoms/Text'
import Menu from "../molecules/Menu"
import { getProject, updateProjectShareStatusApi } from '../services/project.service'
import { setProjectDetails } from '../store/reducers/project'
import Modal from './Modal'

const Share = ({ basePath = process.env.NEXT_PUBLIC_BASE_PATH, url, setOpenShareModel }) => {
  const [textCopy, setTextCopy] = useState(false);
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);
  const [checked, setChecked] = useState(project?.isPublic || false);
  const [isOpen, setOpen] = useState(false);
  const [buttonValue, setButtonValue] = useState(project?.isPublic ? "Can view" : 'Can edit');

  const shareLink = async () => {
    setTextCopy(false);
    try {
      await updateProjectShareStatusApi(project.id, !checked);
      const projectResult = await getProject(project.id);
      const { details } = handleApiResponse(
        projectResult,
        "Failed Fetching Project"
      );
      delete details.users_dbml
      dispatch(setProjectDetails(details));
      setChecked(details.isPublic);
    } catch (error) {
      console.log("🚀 ~ file: Share.js:22 ~ onchange ~ error", error);
    }
  };
  const handleDropdown = (e) => {
    e.stopPropagation();
    if(buttonValue === e.currentTarget.textContent) {setOpen(false); setTextCopy(false); return;}
    setButtonValue(e.currentTarget.textContent);
    setOpen(false);
    if (e.currentTarget.textContent === 'Can view') {
      setChecked(true)
    } else {
      setChecked(false)
    }
    shareLink();
  };
  useEffect(() => {
    setChecked(project?.isPublic || false)
  }, [project]);

  return (
    <Modal classForParent="pt-[120px]" classForChildren="max-w-[448px]" onClick={() => setOpenShareModel(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-3 border-b border-gray-200 px-6 py-4">
          <Heading type="h6" className="text-gray-700 font-semibold leading-7">
            Share
          </Heading>
          <span
            className="cursor-pointer flex items-center"
            onClick={() => {
              setOpenShareModel(false);
            }}
          >
            <Image
              src={`${basePath}/images/Close-3.svg`}
              alt="close"
              width="32"
              height="32"
            />
          </span>
        </div>
        <div className="px-5 pb-4">
          <div className="flex items-center space-x-2">
            <Text className="text-gray-600 text-sm font-semibold">
              Anyone with the link
            </Text>
            <div className="relative">
              <Button
                classForIcon={`flex items-center transform ${isOpen ? "rotate-180" : "rotate-0"
                  }`}
                iconShow={true}
                imgSrc="/images/arrow-down-purplebold.svg"
                imgAlt="arrow"
                imgWidth="16"
                imgHeight="16"
                basePath={basePath}
                className={`bg-primary-100 rounded-md py-1.5 px-3 flex flex-row-reverse items-center gap-x-1.5 text-primary-900 text-xs font-semibold transition duration-300 border ${isOpen && " border-primary-900"
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!isOpen);
                }}
              >
                {buttonValue}
              </Button>
              {isOpen && (
                <Menu
                  className="w-[96px] absolute top-full mt-1 border border-gray-200 shadow-sm"
                  listOfMenu={[
                    {
                      id:"menu01",
                      behaviour: (e) => {
                        handleDropdown(e);
                      },
                      showOption: true,
                      anchorId: "canEdit",
                      name: "Can edit",
                      classForList: 'text-xs font-normal py-1.5 px-2 rounded-b-none'
                    },
                    {
                      id:"menu02",
                      behaviour: (e) => {
                        handleDropdown(e);
                      },
                      showOption: true,
                      anchorId: "CanView",
                      name: "Can view",
                      classForList: 'text-xs font-normal py-1.5 px-2 rounded-t-none'
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div className="flex gap-x-1 mt-4">
            <div className="bg-gray-50 pl-4 py-3 rounded-lg truncate">
              <Text className="text-gray-500 text-xs font-normal leading-4 pr-14 truncate">
                {url}
              </Text>
            </div>
            <Button
              iconShow={false}
              className="bg-purple-700 font-semibold text-xs rounded-lg cursor-pointer py-2 px-4 text-white outline-none whitespace-pre"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(url);
                setTextCopy(true);
              }}
              id="copyUrl"
            >
              {textCopy ? "Copied" : "Copy link"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Share;
