import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';

const moment = require('moment');

export function dateToDMMMYYYY(date) {
    return moment(date).format('DD MMM, YYYY');
}

export function joinArrayToString(arrayObj, property) {
    const categoriesArray = arrayObj.map((ele) => ele[property]);
    return categoriesArray.join(', ');
}

export function getFileNameFromUrl(url) {
    if (!url) return null;
    const urlArray = url.split('/');
    const urlItem = urlArray[urlArray.length - 1].split('-');
    urlItem.splice(0, 5);
    return urlItem.join('-');
}

export function createFileName(fileName) {
    const fileId = uuidV4();
    return (`${fileId}-${fileName}`);
}


export function handleApiResponse(res, type = 'object', defaultErrMsg = 'Something went wrong!') {
    let details = {};
    let apiError = [];
    if (R.pathOr(false, ['data', 'status'], res)) {
        const defaultRes = type === 'object' ? {} : [];
        details = R.pathOr(defaultRes, ['data', 'entity'], res);
    } else {
        apiError = R.pathOr([defaultErrMsg], ['data', 'message'], res);
        if (typeof (apiError) === 'string') {
            apiError = [apiError];
        }
    }

    return { details, apiError };
}


