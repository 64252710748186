import PropTypes from "prop-types";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../atoms/Button";
import Label from "../../atoms/Label";
import { eventTrigger } from "../../lib/gtm";
import { yupValidator } from "../../lib/yup-validator";
import Dropdown from "../../molecules/Dropdown2";
import { setDbConnection } from "../../services/project.service";
import { deleteTables } from "../../services/table.service";
import REIMPORTWARNING from "../ReImport/Warning";

const ConnectToDb = ({
  setConnectToDb,
  projectId,
  tables,
  setErrorMessage,
  setShowIncorrectDetails,
  setIsLoading,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  className,
  setShowFileImport
}) => {
  const defaultValues =  {
      dialect: "",
      host: "",
      port: "", // host 5432  3306
      user: "",
      passwd: "",
      databaseName: "",
    };
    

  let classforinput =
    "bg-white border border-gray-300 focus:border-purple-700 text-gray-800 focus:placeholder-transparent rounded-md py-2 px-4 rounded-lg focus:outline-none text-gray-500 font-normal text-xs leading-5";

  const [dummyText] = useState("");
  const [showReImportWarning, setShowReImportWarning] = useState(false);
  const [data, setData] = useState({});
  const isEnabled = true;

  const options = [
    { value: "postgres", label: "Postgres" },
    { value: "mysql", label: "mySql" },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors ,isValid},
    control,
    clearErrors,
  } = useForm({
    mode: "onChange",
    resolver: yupValidator(
      yup.object().shape(
        {
          dialect: yup
            .string()
            .required("Connection String/dialect is required!"),
          host: yup.string().required("Host is required!"),
          port: yup.string().required("Port is required!"),
          user: yup.string().required("User is required!"),
          passwd: yup.string().required("Password is required!"),
          databaseName: yup.string().required("Database name is required!"),
        },
        []
      )
    ),
    defaultValues,
  });

  const connectToDbApi = async (dbData) => {
    const dbName = dbData.databaseName;
    const host = dbData.host;
    const port = dbData.port;
    const user = dbData.user;
    const passwd = dbData.passwd;
    const dialect = dbData.dialect;
    const noInitModels = true;
    const noWrite = true;
    const dbInfo = {
      dbName,
      host,
      port,
      user,
      passwd,
      dialect,
      noInitModels,
      noWrite,
    };
    await deleteTables(projectId);
    const savedDb = await setDbConnection(projectId, dbInfo);
    setIsLoading(false);
    if (savedDb.data.status) {
      eventTrigger("CE_Connect_to_DB", {
        projectId,
      });
      location.reload();
    } else {
      console.log(savedDb.data.message, "hello");
      setShowIncorrectDetails(true);
      setErrorMessage(savedDb.data.message);
    }
  };

  const onSubmit = (data) => {
    setData(data);
    localStorage.removeItem("dbmlNodes");
    //Developer can view the displayed warning for any action that may affect the current diagram
    setShowReImportWarning(true);
  };

  const proceed = () => {
      setIsLoading(true);
      connectToDbApi(data);
      setShowFileImport(false);
  };

  return (
    <div  className={`${className}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
        <div>
          <Label className={`text-gray-900 text-sm font-normal`}>
            Connection type
          </Label>
          <Controller
            id="dialect"
            name="dialect"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="dialect"
                placeholder="Connection type"
                onChange={(e) => {
                  onChange(e.value);
                  setValue("dialect", e.value);
                  clearErrors("dialect");
                }}
                options={options}
                error={errors}
                isMulti={false}
              />
            )}
          />
        </div>
        </div>
        <div>
          <div className="flex gap-2 mb-1.5">
            <Label className={`text-gray-900 text-sm font-normal w-1/2`}>
              Host
            </Label>
            <div className="relative w-2/5">
              <Label className={`text-gray-900 text-sm font-normal w-1/2`}>
                Port
              </Label>
            </div>
          </div>
          <div className="flex gap-2">
            <input
              className={`${classforinput} w-1/2`}
              id="host"
              {...register("host")}
              placeholder="Enter host name"
              type="text"
              varient="normal"
              errors={errors}
            />
            <input
              className={`${classforinput} w-1/2`}
              id="port"
              {...register("port")}
              classforinput={`${classforinput}`}
              classforlabel="text-gray-900"
              labeltext="Port"
              placeholder="Enter port number"
              type="number"
              varient="normal"
              errors={errors}
            />
          </div>
          <div className="flex gap-2">
            <p className="text-red-400 text-sm flex gap-2 w-1/2">
              {errors.host?.message}
            </p>
            <div className="relative w-1/2">
              <p className="text-red-400 text-sm flex gap-2">
                {errors.port?.message}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-2 mb-1.5">
            <Label className={`text-gray-900 text-sm font-normal w-2/5`}>
              User
            </Label>
            <div className="relative w-2/5">
              <Label className={`text-gray-900 text-sm font-normal w-3/5`}>
                Password
              </Label>
            </div>
          </div>
          <div className="flex gap-2">
            <input
              className={`${classforinput} w-2/5`}
              id="user"
              {...register("user")}
              labeltext="User"
              placeholder="Enter username"
              type="text"
              varient="normal"
              errors={errors}
            />
            <div className="relative w-3/5">
              <input
                className={`${classforinput} w-full focus:border-purple-700 focus:placeholder-transparent ${
                  dummyText.length > 0 ? "caret-transparent" : "caret-black"
                }`}
                id="passwd"
                {...register("passwd")}
                labeltext="Password"
                placeholder="Enter password"
                type="password"
                varient="password"
                autoComplete="on"
                errors={errors}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <p className="text-red-400 text-sm flex gap-2 w-2/5">
              {errors.user?.message}
            </p>
            <div className="relative w-3/5">
              <p className="text-red-400 text-sm flex gap-2">
                {errors.passwd?.message}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-2 mb-1.5">
            <Label className={`text-gray-900 text-sm font-normal w-full`}>
              Database name
            </Label>
          </div>
          <div className="flex gap-2 ">
            <input
              className={`${classforinput} w-full`}
              id="databaseName"
              {...register("databaseName")}
              labeltext="Database name"
              placeholder="Enter database name"
              type="text"
              varient="normal"
              errors={errors}
            />
          </div>
          <div className="relative w-full">
            <p className="text-red-400 text-sm flex gap-2">
              {errors.databaseName?.message}
            </p>
          </div>
        </div>
        <div className="pt-4">
          <Button type='submit' className={`w-full text-white font-semibold text-sm rounded-md  py-2 ${isValid ?'bg-primary-900':'bg-primary-500'}`}  iconShow={false}>Connect</Button>
        </div>
      </form>
      {showReImportWarning && (
        <REIMPORTWARNING
          setShowReImportWarning={setShowReImportWarning}
          proceed={proceed}
          // text="All the changes you have made to the current file will be overridden.
          // Are you sure you want to import a new file?"
        />
      )}
    </div>

  );
};

ConnectToDb.propTypes = {
  // setConnectToDb: PropTypes.func,
  projectId: PropTypes.string,
  basePath: PropTypes.string,
};

export default ConnectToDb;
