import EmptyLayout from './EmptyLayout';
import Layout from './Layout';

const LayoutConfig = [
  {
    path: '/authentication',
    layout: EmptyLayout
  },
  {
    path: '/login',
    layout: EmptyLayout
  },
  {
    path: '/404',
    layout: EmptyLayout
  },
  {
    path: '/db-view/details',
    layout: EmptyLayout
  },
  {
    path: '/unsubscribe/[emailId]',
    layout: EmptyLayout
  },
  {
    path: '/',
    layout: Layout
  },
  {
    path: '',
    layout: EmptyLayout
  }
];

const getLayout = (path) => {
  const configuration = LayoutConfig.find((config) => path.includes(config.path));
  if (configuration) return configuration.layout;
  return EmptyLayout;
};

export default getLayout;