import React from 'react'
import PropTypes from 'prop-types';

const Label = ({children ,labelColor,labelSize, className }) => {
  return (
    <label className={`${labelColor} ${labelSize}   ${className}`}>{children}</label>
  )
}

Label.propTypes={
  labelColor:PropTypes.string,
  labelSize:PropTypes.string,
  className:PropTypes.string,
  children:PropTypes.node.isRequired,
}

export default Label