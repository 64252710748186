import Image from "next/image";
import React from "react";
const Video = ({ className ,basePath = process.env.NEXT_PUBLIC_BASE_PATH ,videoSrc ,setShowVideo}) => {
  return ( 
    <div className={`bg-black bg-opacity-50 h-screen w-screen fixed inset-0 flex justify-center items-center z-50  ${className}`} onClick={()=>setShowVideo(false)}>
      <div className="w-3/5 relative  shadow-md cursor-pointer">
          <span className="transform duration-200 absolute -top-2 -right-7 cursor-pointer " onClick={()=>setShowVideo(false)}> 
            <Image src={`${basePath}/images/tableClose.svg`} alt="Close"  height="24" width="24"/>
          </span>
            <video
              autoPlay
              playsInline
              controls
              className="w-full h-full"
            >
              <source src={`${basePath}${videoSrc}`} type="video/mp4" />
                  Your browser does not support the video tag.
            </video>
      </div>
    </div>
  );
};

export default Video;
