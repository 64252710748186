export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url) => {
    window.dataLayer?.push({
        event: 'pageview',
        page: url
    });
};
export const eventTrigger = (event, data) => {
    if (GTM_ID !== 'dummy') {
        window.dataLayer?.push({
            event, data
        });
    }
}
