import { removeAuth } from '@/services/identity.service';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from "prop-types";
import Text from '../atoms/Text';
import TextAvatar from '../atoms/TextAvatar';
const Logout = ({
  className, firstLetter, userName, basePath = process.env.NEXT_PUBLIC_BASE_PATH
}) => {
  const router = useRouter();
  const handleLogOut = () => {
    removeAuth();
    localStorage.clear();
    sessionStorage.clear();
    router.push('/login');
  };

  return (
    <div
      className={`border border-gray-200 bg-white rounded-lg shadow-lg ${className}`}
    >
      <div className="flex pl-2.5 items-center py-2 gap-2 bg-gray-60 border-b border-gray-200 border-t rounded-t-lg">
        <TextAvatar className="w-7 h-7 text-xs" firstLetter={firstLetter} />
        <Text className="text-xs font-medium leading-7 text-gray-500">
          {userName}
        </Text>
      </div>
      <div
        className="flex pl-3.5 items-center py-2 bg-white gap-3.5 cursor-pointer rounded-b-lg user-logout hover:bg-gray-50"
        onClick={handleLogOut}
      >
        <Image
          src={`${basePath}/images/logout.svg`}
          width={16}
          height={16}
          alt="logout"
        />
        <Text className="text-sm font-normal leading-7 text-gray-500">
          Logout
        </Text>
      </div>
    </div>
  );
};

Logout.propTypes = {
  className: PropTypes.string,
  firstLetter: PropTypes.string,
  userName: PropTypes.string,
  basePath: PropTypes.string
}
export default Logout;
