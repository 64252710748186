/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { allTags: [], selectedAllTags: [] };

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    initTagState(state, actions) {
      state.allTags = actions.payload
    },

    createNewTag(state, actions) {
      state.allTags = [...state.allTags, actions.payload]
    },

    editTag(state, actions) {
      const tagIndex = state.allTags.findIndex(tag => tag.id === actions.payload.id)
      state.allTags[tagIndex] = actions.payload;
    },

    deleteTag(state, actions) {
      const tagArray = state.allTags.filter(tag => tag.id !== actions.payload.id)
      state.allTags = tagArray;
    },

    setAppliedTagFilter(state, actions) {
      state.selectedAllTags = actions.payload
    },
    
  }
});

export const { initTagState, createNewTag, editTag, setAppliedTagFilter, deleteTag } = tagSlice.actions;

export default tagSlice.reducer;
