import PropTypes from "prop-types";
import Alert from "../atoms/Alert";
import SearchExpandList from "./SearchExpandList";
const SearchExpand = ({
  className,
  value,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  focusNode,
  resultContainer,
  fileredData,
  focusedIndex,
  handleSelection,
}) => {

  return (
    <div
      className={`w-[320px] h-[232px] border border-gray-300 bg-white rounded-b-lg overflow-y-auto overflow-x-hidden scrollbar ${className}`}
    >
      {fileredData.length ? (
        fileredData.map((table,index) => {
          return (
            <SearchExpandList
              className="w-full"
              tableName={table.name}
              key={table.id}
              handleClick={focusNode}
              basePath={basePath}
              resultContainer={resultContainer}
              index={index}
              focusedIndex={focusedIndex}
              handleSelection={handleSelection}
            />
          );
        })
      ) : (
        <Alert
          alertText="The table you are looking for does not exist."
          className=""
        />
      )}
    </div>
  );
};
SearchExpand.propTypes = {
  className: PropTypes.string,
  listOfTableNames: PropTypes.array,
  value: PropTypes.string,
  updateInputField: PropTypes.func,
};

export default SearchExpand;
