import PropTypes from "prop-types";
const Modal = ({classForParent,classForChildren,children,onClick, border='border border-gray-200', borderRadius='rounded-lg'}) => {
  return (
    <div className={`h-screen bg-black bg-opacity-75 fixed inset-0 z-50 ${classForParent}`} onClick={(e)=>{e.stopPropagation(),onClick && onClick()}}>
    <div className={`bg-white shadow-sm relative mx-auto ${borderRadius} ${border} ${classForChildren}`}>
       {children}
    </div>
 </div>
  )
}
Modal.propTypes = {
  classForParent:PropTypes.string,
  classForChildren:PropTypes.string,
 children:PropTypes.node
}
export default Modal
