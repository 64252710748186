import Image from 'next/image';
import PropTypes from "prop-types";
import { useState } from 'react';
import Button from '../../atoms/Button';
import Paragraph from '../../atoms/Paragraph';
import Modal from '../../molecules/Modal';
const Warning = ({
  className,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  setShowReImportWarning,
  proceed,
  text="This action will override the current file and any changes made, will not be saved. If you wish to start fresh, please click on “Continue”.",
}) => {
  const [hide, setHide] = useState(false);

  const handleClose = () => {
    setHide(true);
    setShowReImportWarning(false);
  };

  const handleContinue = (e) => {
    e.stopPropagation();
    setHide(true);
    setShowReImportWarning(false);
    proceed();
  };

  return (
    <Modal
      classForParent={`pt-[66px] z-50 ${hide ? "hidden" : "block"}`}
      classForChildren="w-[356px] py-4 relative"
      className=""
    >
      <span
        className="absolute right-3 top-3 cursor-pointer"
        onClick={handleClose}
      >
        <Image
          src={`${basePath}/images/Close-2.svg`}
          alt={"clkose icon"}
          width={16}
          height={16}
        />
      </span>
      <div className="text-center">
        <span className="">
          <Image
            src={`${basePath}/images/warning-2.svg`}
            width={44}
            height={44}
            alt={"warning"}
          />
        </span>
        <Paragraph className="text-gray-600 text-xs leading-5 font-normal mt-[15px] px-2">
          {text}
        </Paragraph>
        <Button
          id="warning"
          className="bg-purple-700 text-gray-50 text-sm font-semibold rounded-lg leading-5 py-2 px-4 mx-auto mt-5 cursor-pointer"
          onClick={handleContinue}
          iconShow={false}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

Warning.propTypes = {
  className: PropTypes.string,
  basePath: PropTypes.string,
  setShowReImportWarning: PropTypes.func
}
export default Warning