import Text from "@/atoms/Text";
import { getInitials } from "@/helpers/miscelleanous";
import { eventTrigger } from "@/lib/gtm";
import { getProject, updateProjectNameApi } from "@/services/project.service";
import { deleteTables } from "@/services/table.service";
import { toPng } from "html-to-image";
import Image from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { useReactFlow, useStoreApi } from "reactflow";
import Heading from "../../atoms/Heading";
import Input from "../../atoms/Input";
import TextAvatar from "../../atoms/TextAvatar";
import Tooltip from "../../atoms/Tooltip";
import ViewOnly from "../../atoms/ViewOnly";
import Loader from "../../molecules/Loader";
import Logout from "../../molecules/Logout";
import Menu from "../../molecules/Menu";
import SearchExpand from "../../molecules/SearchExpand";
import SearchField from "../../molecules/SearchField";
import Share from "../../molecules/Share";
import { handleApiResponse } from "../../services/helper-function.service";
import { getAuth } from "../../services/identity.service";
import { getTables } from "../../services/table.service";
import ImportFileModel from "../ImportFile/ImportFileModel";
import IncorrectDetails from "../IncorectDetails/IncorrectDetails";
import REIMPORTWARNING from "../ReImport/Warning";
import Video from "../VideoPlayer/Video";

function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", "dbdaddy_diagram.jpeg");
  a.setAttribute("href", dataUrl);
  a.click();
}

const Navbar = ({ basePath = process.env.NEXT_PUBLIC_BASE_PATH }) => {
  const auth = getAuth();
  const router = useRouter();
  const [value, setValue] = useState("");
  const [hasFocus, setFocus] = useState(false);
  const [tables, setTables] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const { projectId } = router.query;
  const [showReImportWarning, setShowReImportWarning] = useState(false);
  const [showClearDiagramWarning, setShowClearDiagramWarning] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const store = useStoreApi();
  const { setCenter, getZoom } = useReactFlow();
  const [originPath, setOriginPath] = useState("");
  const [routePath, setRoutePath] = useState("");
  const [targetFile, setTargetFile] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [fileName, setFileName] = useState("Untitled Diagram");
  const [zoomValue, setZoomValue] = useState(0.75);
  const [shareModel, setOpenShareModel] = useState(false);
  const ref = useRef(true);
  const { project } = useSelector((state) => state.project);
  const [isPublic, setIsPublic] = useState(false);
  const [showIncorrectDetails, setShowIncorrectDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something Went Wrong!");
  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showFileImport, setShowFileImport] = useState(false);
  const [focusedIndex, setFocusIndex]=useState(-1);
  const resultContainer=useRef(null);

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    } else {
      setZoomValue(getZoom());
    }
  }, [getZoom()]);

  const focusNode = (e, targetNode) => {
    e.stopPropagation();
    setValue(targetNode);
    setFocus(false);
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, nd]) => nd);

    const node = nodes.find((nd) => nd.id === targetNode);
    eventTrigger("CE_TABLE_SEARCHED", {
      table: node,
    });
    if (!node) {
      window.dispatchEvent(
        new CustomEvent("tableSearch", { detail: targetNode })
      );
      return;
    }

    const x = node.position.x + node.width / 2;
    const y = node.position.y + node.height / 2;

    setCenter(x, y, { zoom: zoomValue, duration: 1000 });
    window.dispatchEvent(new CustomEvent("highlitTable", { detail: node }));
  };

  useEffect(() => {
    if (!value) {
      window.dispatchEvent(new Event("removeHighlitTable"));
    }
  }, [value]);

  useEffect(() => {
    setOriginPath(window.location.origin);
    setRoutePath(window.location.pathname);
    getAllTables();
    getProjectDetails();
  }, [projectId]);

  const getAllTables = async () => {
    try {
      const response = await getTables(projectId, 0, 9999, "");
      const { details: allTables } = handleApiResponse(
        response,
        "List all Tables api failure!"
      );

      const filterEnum = allTables.rows
        .map((table) => {
          if (table.type !== "enum")
            return { name: table.tableName, id: table.id };
        })
        .filter(Boolean);

      setTables(filterEnum);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileSelect = (e) => {
    e.stopPropagation();
    setShowFileImport(false);
    setTargetFile(e.target.files || e.dataTransfer.files);
    if (tables.length > 0 && !showDropDown) {
      // const { isSample } = auth;

      // // If View Contains Sample Diagram Let it clear without showing any warnings
      // if (isSample) {
      //   handleChange(e.target.files);
      //   e.target.value = null;
      //   setAuth({
      //     ...auth,
      //     isSample: false,
      //   });
      // } else {
      return setShowReImportWarning(true);
      // }
    }
    handleChange(e.target.files || e.dataTransfer.files);
    e.target.value = null;
  };

  const handleChange = (file) => {
    let files = file || targetFile;
    const dbmlFileRead = files[0];
    if (dbmlFileRead) {
      const reader = new FileReader();
      const fileType = dbmlFileRead.name.split(".").pop();
      if (fileType === "dbml") {
        eventTrigger("CE_Import_DBML");
      }
      if (fileType === "sql") {
        eventTrigger("Import_SQL");
      }
      if (fileType === "dbml" || fileType === "sql") {
        reader.readAsText(dbmlFileRead);
        reader.onload = function () {
          if (document.onFileUpload) {
            document.onFileUpload(reader.result, fileType);
          }
        };
      } else if (document.onInvalidUpload) {
        document.onInvalidUpload(reader.error);
      }
    }
  };

  const handleClickOutside = () => {
    setFocus(false);
    setShowDropDown(false);
    setShowLogout(false);
    setShowInput(false);
    setFocusIndex(-1);
  };

  const openLogout = (e) => {
    e.stopPropagation();
    setShowLogout(!showLogout);
    setShowDropDown(false);
  };

  const handleCreateNewDiagram = async () => {
    const response = await deleteTables(projectId);
    if (response.status) {
      location.reload();
    }
  };

  const handleCreateNewDiagramWithWarning = () => {
    setShowDropDown(false);
    localStorage.removeItem("dbmlNodes");
    // Developer can view the displayed warning for any action that may affect the current diagram
    setShowClearDiagramWarning(true);
  };

  const getProjectDetails = async () => {
    try {
      const response = await getProject(projectId);
      const { details: project } = handleApiResponse(response);
      if (project.isSample) {
        setFileName("Sample Diagram");
      } else {
        setFileName(project.fileName);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: FlowContainer.js ~ line 110 ~ getdbmlFile ~ error",
        error
      );
    }
  };

  const handleUpdateProjectName = async (e) => {
    try {
      // If fileName is empty do not update
      if (!fileName) {
        await getProjectDetails();
        return;
      }

      // TODO : call updateProjectName Api
      await updateProjectNameApi(projectId, fileName);
    } catch (error) {
      console.log(
        "🚀 ~ file: Navbar.js:220 ~ handleUpdateProjectName ~ error",
        error
      );
    }
  };

  const handleKeyPressProjectName = async (e) => {
    if (e.which === 13 || e.keyCode === 13 || e.key === "Enter") {
      e.preventDefault();
      await handleUpdateProjectName();
    }
  };

  useEffect(() => {
    window.addEventListener("updateTableList", getAllTables);
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      window.removeEventListener("updateTableList", getAllTables);
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    setIsPublic(auth == undefined || (auth.id != project?.user_id && project.isPublic));
  }, [project]);

  /**
   * Function to export diagram as Image
   */
  const HandleExportAsImage = () => {
    const filter = (node) => {
      const exclusionClasses = [
        "react-flow__minimap",
        "react-flow__controls",
        "react-flow__attribution",
      ];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    const target = document.querySelector(".react-flow");

    const elements = document.getElementsByClassName("react-flow__node");

    let tranformWidth = [];
    let tranformHeight = [];

    for (let element of elements) {
      const x = element.style.transform
        .split("(")[1]
        .split(",")[0]
        .slice(0, -2);
      const y = element.style.transform
        .split("(")[1]
        .split(",")[1]
        .slice(0, -3);
      tranformHeight.push(parseInt(y));
      tranformWidth.push(parseInt(x));
    }

    if (tranformHeight.length != 0 && tranformWidth.length != 0) {
      const flowWidth = tranformWidth.reduce((a, b) => Math.max(a, b));
      const flowWidthLeft = tranformWidth.reduce((a, b) => Math.min(a, b));
      const flowHeight = tranformHeight.reduce((a, b) => Math.max(a, b));
      const flowHeightTop = tranformHeight.reduce((a, b) => Math.min(a, b));
      let leftTranslate = 150;
      let topTransalte = 185;

      if (flowWidthLeft < 0) {
        leftTranslate = Math.abs(flowWidthLeft);
      }
      if (flowHeightTop < 0) {
        topTransalte = Math.abs(flowHeightTop);
      }

      const flowContainer = document.querySelector(".react-flow__container");
      const initialPosition = flowContainer.style.transform;
      flowContainer.style.transform = `translate(${leftTranslate}px, ${topTransalte}px) scale(0.75)`;

      toPng(target, {
        filter: filter,
        backgroundColor: "#fff",
        height: flowHeight + topTransalte + 200,
        width: flowWidth + leftTranslate,
      }).then(downloadImage);
    }
  };
 
  const fileredData = tables.filter((tableName) => {
    if (tableName.name.toLowerCase().includes(value.toLowerCase())) {
      return tableName
    }
  })

  const handleKeyDown=(e)=>{
    const {key} = e;
    let nextIndexCount = 0;

    if(key==='ArrowDown') nextIndexCount=(focusedIndex+1)%fileredData.length;

    if(key==='ArrowUp') nextIndexCount=(focusedIndex+fileredData.length-1)%fileredData.length;

    if(key==='Enter'){//select the focused index
         e.preventDefault();
         handleSelection(e,focusedIndex);
    }
    setFocusIndex(nextIndexCount)
  }

    useEffect(()=>{
      if(!resultContainer.current) return;
      resultContainer.current.scrollIntoView({ behavior: "smooth", block: "end"});
    },[focusedIndex]);//for scroll on key up and down
  
    const onSelect=(e,item) => {
      setValue(item.name)
      focusNode(e, item.name)
    }

  const handleSelection = (e,selectedIndex)=>{
    const selectedItem=fileredData[selectedIndex];
    if(!selectedItem) return resetSearchComplete();
    onSelect && onSelect(e,selectedItem);
    resetSearchComplete();
  }

  const resetSearchComplete = useCallback(()=>{//Set focusindex to -1 so it will remove list focus;
   setFocusIndex(-1);
  },[])


  /**
   *  Shortcut Keys
   */
  const searchRef = useRef(null);
  useHotkeys(
    "s",
    () => {
      setValue("");
      setFocus(true);
      searchRef?.current?.focus();
    },
    { preventDefault: true },
    [searchRef]
  );

  let listOfMenu = [
    // {
    //   id: "menu01",
    //   anchorId: "backtofiles",
    //   behaviour: (e) => {},
    //   classForImage: "",
    //   imgAlt: "arrow",
    //   imgSrc: "/images/black-arrow-left.svg",
    //   hoverImage: "/images/purple-arrow-left.svg",
    //   name: "Back to files",
    //   showIcon: true,
    //   classForList:
    //     "activateImage flex gap-2 py-3 px-2 text-sm leading-[22px] text-gray-700 leading-5 hover:bg-primary-50 border-b border-gray-50 rounded-t-md",
    //   basePath: process.env.NEXT_PUBLIC_BASE_PATH,
    // },
    {
      id: "menu02",
      anchorId: "createnew",
      behaviour: handleCreateNewDiagramWithWarning,
      showOption: !isPublic,
      classForImage: "",
      showIcon: true,
      imgAlt: "add-square",
      imgSrc: "/images/add-square.svg",
      hoverImage: "/images/purple-add-square.svg",
      className: "ml-2",
      name: "Create new diagram",
      classForList:"activateImage flex py-2.5 px-2 text-sm  relative group rounded-t-md",
      basePath: process.env.NEXT_PUBLIC_BASE_PATH,
    },
    {
      id: "menu03",
      anchorId: "Import",
      behaviour: () => setShowFileImport(true),
      showOption: !isPublic,
      classForImage: "",
      imgAlt: "export",
      imgSrc: "/images/frame.svg",
      hoverImage: "/images/frame-purple.svg",
      name: "Import",
      showIcon: true,
      classForList: "activateImage flex gap-2 py-2.5 px-2 text-sm",
      basePath: process.env.NEXT_PUBLIC_BASE_PATH,
    },
    {
      id: "menu03",
      anchorId: "ExportImage",
      behaviour: HandleExportAsImage,
      showOption: !isPublic,
      classForImage: "",
      imgAlt: "export",
      imgSrc: "/images/export.svg",
      hoverImage: "/images/purple-export.svg",
      name: "Export to JPG",
      showIcon: true,
      classForList:"activateImage flex gap-2 py-2.5 px-2 text-sm",
      basePath: process.env.NEXT_PUBLIC_BASE_PATH,
    },
    {
      id: "menu04",
      anchorId: "help",
      behaviour: (e) => {
        e.stopPropagation();
      },
      showOption: true,
      classForImage: "",
      showIcon: true,
      imgAlt: "help",
      imgSrc: "/images/message-question.svg",
      hoverImage: "/images/purple-message-question.svg",
      className: "ml-2",
      _imgAlt: "arrow",
      _imgSrc: "/images/arrowup.svg",
      _hoverImage: "/images/purple-arrowup.svg",
      _classForImage: "ml-[120px]",
      _showIcon: true,
      name: "Help",
      classForList:
        "activateImage flex py-2.5 px-2 text-sm  leading-5  relative group rounded-b-md",
      basePath: process.env.NEXT_PUBLIC_BASE_PATH,
      subMenu: [
        {
          id: "submenu01",
          anchorId: "redirectToDocs",
          href: "https://napses.notion.site/How-it-Works-745e58ff438946dfbfe891c97f8a6fb5",
          name: "Docs",
          target: "_blank",
          className: "flex",
          behaviour: () => setShowDropDown(false),
          classForList:"rounded-t-md",
        },
        {
          id: "submenu02",
          anchorId: "dbmlDocs",
          behaviour: () => setShowDropDown(false),
          href: "https://napses.notion.site/napses/DBDaddy-DBML-Syntax-3718a6b3883d4c65b7ae3e69a73e7c9c",
          target: "_blank",
          name: "DBML Syntax",
          className: "flex",
        },
        {
          id: "submenu03",
          anchorId: "viewDemo",
          behaviour: () => {
            setShowVideo(true), setShowDropDown(false);
          },
          name: "View demo",
          className: "flex",
          classForList:"rounded-b-md",
        },
      ],
    },
  ];

  return (
    <>
      {showReImportWarning && (
        <REIMPORTWARNING
          setShowReImportWarning={setShowReImportWarning}
          proceed={handleChange}
        // text="This action will override the current file and any changes made, will not be saved. If you wish to start fresh, please click on “Continue”."
        />
      )}
      {showClearDiagramWarning && (
        <REIMPORTWARNING
          setShowReImportWarning={setShowClearDiagramWarning}
          proceed={handleCreateNewDiagram}
        // text="All the changes you have made to the current file will be overridden.
        // Are you sure you want to create a new diagram?"
        />
      )}
      {showIncorrectDetails && (
        <IncorrectDetails
          message={errorMessage}
          handleClick={() => setShowIncorrectDetails(false)}
        />
      )}
      {isLoading && <Loader />}
      {showVideo && (
        <Video
          basePath={basePath}
          setShowVideo={setShowVideo}
          videoSrc="/video/Dbdaddy-video.mp4"
        ></Video>
      )}
      {shareModel && (
        <Share
          basePath={basePath}
          url={originPath + routePath}
          setOpenShareModel={setOpenShareModel}
        />
      )}
      {showFileImport && (
        <ImportFileModel
          basePath={basePath}
          setShowFileImport={setShowFileImport}
          handleFileSelect={handleFileSelect}
          projectId={projectId}
          tables={tables}
          setShowIncorrectDetails={setShowIncorrectDetails}
          setErrorMessage={setErrorMessage}
          showIncorrectDetails={showIncorrectDetails}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="bg-white h-14 flex justify-between items-center px-3 py-2 relative border-b borde-gray-50">
        <div className="flex gap-3 items-center">
          <div className="border-r border-gray-200 flex items-center pr-2">
            <Image
              src={`${basePath}/images/logo-dbdaddy.svg`}
              alt="logo"
              width="91"
              height="35"
            />

            <div className="ml-1 cursor-pointer relative z-40  transform duration-300">
              <div
                className={`h-6 w-6 rounded flex items-center rotate-90 ${showDropDown
                  ? "bg-primary-100"
                  : "bg-white hover:bg-gray-50 "
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                  setShowLogout(false);
                  setFocus(false);
                }}
              >
                <Image
                  src={`${basePath}${showDropDown
                    ? "/images/purple-dots.svg"
                    : "/images/black-dots.svg"
                    }`}
                  alt="dots"
                  width="24"
                  height="24"
                />
              </div>
              {showDropDown && (
                <Menu
                  className="min-w-[200px] absolute top-7 left-0 shadow-8pt"
                  rounded="rounded-md"
                  shadow="shadow-xs"
                  border="border border-gray-50"
                  classForSubmenu="w-[157px] border border-gray-50 shadow-xs rounded-md absolute left-full bg-white -top-0.5 hidden group-hover:block"
                  listOfMenu={listOfMenu}
                />
              )}
            </div>

          </div>
          <div
            className="flex gap-0.5 items-center group"
            onClick={(e) => e.stopPropagation()}
          >
            {showInput ? (
              <Input
                type="text"
                name="filename"
                className="text-gray-700 leading-7 font-medium focus:outline-none text-lg w-[163px] bg-gray-50 px-1 py-1.5 caret-gray-700"
                value={fileName}
                onChange={(e) => {
                  e.target.value.length <= 50 && setFileName(e.target.value);
                }}
                onBlur={() => {
                  setShowInput(false);
                  handleUpdateProjectName();
                }}
                onKeyDown={handleKeyPressProjectName}
                autoFocus={true}
                maxLength="50"
              />
            ) : (
              <>
                <div className="max-w-[205px] group/filename relative z-40" onClick={()=>!isPublic && setShowInput(true)}>
                  <Heading
                    type="h6"
                    className="text-gray-700 leading-7 font-medium focus:outline-none text-lg truncate"
                  >
                    {fileName}
                  </Heading>
                  <Tooltip
                    position="hidden absolute top-10 z-50 left-1/2 -translate-x-1/2 group-hover/filename:block"
                    toolTipArrowPosition="left-1/2 -translate-x-1/2 -top-1.5"
                    toolTipContent={fileName}
                    textColor="text-white"
                    padding="px-4 py-1.5"
                    textSize="text-sm"
                  />
                </div>
                {!isPublic && (
                  <span
                    className="hidden group-hover:flex justify-center cursor-pointer h-7 w-7 hover:bg-gray-50 rounded-md relative group/tooltip"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInput(true);
                      setShowDropDown(false);
                      setFocus(false);
                    }}
                  >
                    <Image
                      src={`${basePath}/images/edit.svg`}
                      alt="edit"
                      width="16"
                      height="16"
                    />
                    <Tooltip
                      position="hidden absolute top-10 z-50 group-hover/tooltip:block"
                      toolTipArrowPosition="left-6 -top-1.5"
                      toolTipContent="Edit"
                      textColor="text-white"
                      padding="px-4 py-1.5"
                      textSize="text-sm"
                    />
                  </span>
                )}
              </>
            )}
          </div>
          {isPublic && <ViewOnly basePath={basePath} className="" />}
        </div>

        <div className="hidden md:block fixed left-1/2 transform -translate-x-1/2 z-40" tabIndex={1} onKeyDown={handleKeyDown}>
          <SearchField
            containerClass=""
            imgSrc={`${basePath}/images/search.svg`}
            className={`${value && "pl-[16px]"} ${hasFocus && "pl-[16px] rounded-b-none border-b-0 shadow-md"
              }`}
            value={value}
            setValue={setValue}
            hasFocus={hasFocus}
            setFocus={setFocus}
            searchRef={searchRef}
            setShowDropDown={setShowDropDown}
            setShowLogout={setShowLogout}
          />
          {hasFocus && (
            <SearchExpand
              className="absolute top-8 shadow-md z-40"
              value={value}
              fileredData={fileredData}
              focusNode={focusNode}
              resultContainer={resultContainer}
              focusedIndex={focusedIndex}
              handleSelection={handleSelection}
            />
          )}
        </div>

        {!isPublic &&   <div className="flex items-center gap-4">
           <span
            className="group flex justify-center items-center rounded-lg h-10 w-10 relative cursor-pointer hover:bg-gray-50"
            onClick={() => setOpenShareModel(true)}
          >
            <Image
              src={`${basePath}/images/share-2.svg`}
              alt="share"
              width="24"
              height="24"
            />
            <Tooltip
              position={`hidden group-hover:block absolute top-12 z-50`}
              toolTipArrowPosition="left-1/2 -translate-x-1/2 -top-1.5"
              toolTipContent="Share"
              textColor="text-white"
              padding="px-4 py-1.5"
              textSize="text-sm"
            />
          </span>
            <div
            className="relative flex items-center cursor-pointer border-l border-gray-200 pl-3.5"
            onClick={(e) => {
              openLogout(e);
              setFocus(false);
            }}
          >
            <TextAvatar
              className="w-8 h-8 text-sm leading-[22px] font-normal mr-1"
              firstLetter={getInitials(auth?.fullName || auth?.email)}
            />
            <span
              className={`transform duration-300 h-4 flex items-center ${showLogout ? "rotate-180" : "rotate-0"
                }`}
            >
              <Image
                src={`${basePath}/images/arrow-up.svg`}
                width={12}
                height={12}
                alt="arrow down"
              />
            </span>
            {showLogout && (
              <Logout
                className="absolute w-56 top-[46px] right-0 z-40"
                userName={auth?.fullName || auth?.email}
                firstLetter={getInitials(auth?.fullName || auth?.email)}
                basePath={basePath}
              />
            )}
          </div>
        </div>
        }
      </div>
      <div className="block md:hidden w-full fixed z-2000 bottom-0 left-0 py-6 px-3">
        <div className="flex items-center justify-between gap-2 max-w-[365px] mx-auto bg-[#F2B013] rounded-md px-3 py-2">
          <div className="flex-none flex items-center justify-center">
            <Image
              src={`${basePath}/images/smartphone.svg`}
              width={27}
              height={27}
            />
          </div>
          <Text className="text-white text-xs font-medium leading-5">DBDaddy on mobile is coming soon! Please open this page on your computer for the best experience.</Text>
        </div>
      </div>
    </>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
};

export default Navbar;