import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalErrorPage from '@/components/Errors/GlobalErrorPage';
import { dumpLogsToAwsCloudWatch } from 'services/logger.service';

export default function ErrorHandlerWrapper({children}) {
    function errorBoundaryHandler(error) {
        const body = {
            logMessage: 'BrowserError: Error occured in the component',
            logData: { errorStack: error.stack, message: error.message }
          }
          dumpLogsToAwsCloudWatch(body);
    }

    return (
        <ErrorBoundary
            FallbackComponent={GlobalErrorPage}
            onError={errorBoundaryHandler}
        >
            {children}
        </ErrorBoundary>
    )
}
