import Image from "next/image";
import PropTypes from "prop-types";
import Button from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import Modal from "../../molecules/Modal";
const IncorrectDetails = ({ basePath = process.env.NEXT_PUBLIC_BASE_PATH, message, handleClick }) => {
  return (
    <Modal
      classForParent='flex items-center z-60'
      classForChildren="w-[270px] p-3"
    >
      <div className="text-center">
        <Image
          src={`${basePath}/images/warning-2.svg`}
          alt="warning"
          width={24}
          height={24}
        />
      </div>
      <Paragraph className="text-gray-600 text-sm font-normal mt-2 text-center mb-3">
        {message}
      </Paragraph>
      <Button
        id="incorrectDetails"
        className="w-full text-center bg-purple-700 py-2 text-white rounded-lg text-sm font-semibold"
        iconShow={false}
        handleClick={() => handleClick()}
      >
        OK
      </Button>
    </Modal>
  );
};

IncorrectDetails.propTypes = {
  basePath: PropTypes.string,
  handleClick: PropTypes.func,
  message: PropTypes.string
};

export default IncorrectDetails;
