import React from 'react'
import PropTypes from 'prop-types';
const Text = ({className, children}) => {
  return (
  <>
     <p className={`${className}`}>{children}</p>
  </>
  )
}

Text.propTypes={
    className:PropTypes.string
}

export default Text