import React from 'react'
import PropTypes from "prop-types";
const List = ({children,className,onClick}) => {
  return (
   <li className={`${className}`} onClick={onClick} >{children}</li>
  )
}
List.propTypes = {
  className: PropTypes.string,
  children:PropTypes.node,
  onClick:PropTypes.func,
};
export default List