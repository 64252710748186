import React from "react";

const Tooltip = ({
  position,
  toolTipArrowPosition,
  toolTipContent,
  textColor,
  classForToolTip,
  bgColor = 'bg-gray-800',
  padding = 'py-1 px-3',
  textSize='text-xs'
}) => {
  return (
    <div className={`z-2000 inline-block rounded ${padding} ${bgColor}  ${classForToolTip} ${position}`}>
      <span className={`whitespace-pre ${textSize} ${textColor}`}>
        {toolTipContent}
      </span>
      <div className={`w-3 h-3 transform rotate-45 absolute ${bgColor} ${toolTipArrowPosition}`}></div>
  </div>
  );
};

export default Tooltip;

