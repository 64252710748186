import PropTypes from 'prop-types';
import Select from 'react-select';

const colourStyles = {

    control: (base) => ({
        ...base,
        maxHeight: 'auto',
        minHeight: 36,
        overflowY: 'auto',
        overflowX: 'hidden',
        border: '1px solid #DFE0EB',
        fontSize: '14px ',
        fontWeight: '400'

    }),
    option: (styles, {
        isFocused
    }) => ({
        ...styles,
        backgroundColor: isFocused ? 'bg-gray-60' : null,
        color: 'text-purple-700'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    })
};

const Dropdown2 = ({
    options,
    name,
    defaultValue,
    isDisabled,
    isLoading,
    isMulti,
    onChange,
    defaultValueIndex,
    className,
    labelClass,
    error,
    label,
    ...properties
}) => (

    <div className="w-full flex flex-col gap-2">
        <label className='text-Gray-900 font-medium text-sm'>{label}</label>
        <Select
            instanceId="long-value-select"
            isMulti={isMulti}
            classNamePrefix="select"
            placeholder={`${properties.placeholder ? properties.placeholder : 'Search and select'
            }`}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable
            isSearchable
            name={name}
            closeMenuOnSelect={!isMulti}
            options={options}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#FBC31D'
                }
            })}
            styles={colourStyles}
            onChange={onChange}
            className={`${className}`}
            {...properties}
        />
        {error?.[name] && (
            <div className="text-error text-sm text-red-400">
                {error[name].message}
            </div>
        )}
    </div>
);
export default Dropdown2;

Dropdown2.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValueIndex: PropTypes.number
};

Dropdown2.defaultProps = {
    isDisabled: false,
    isLoading: false,
    isClearable: false,
    isSearchable: false,
    isMulti: false
};
