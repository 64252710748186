import getLayout from '../Layout/LayoutConfiguration';

const App = ({ Component, pageProps, router }) => {

    const layout = getLayout(router.pathname);
    return (
        <>
          {layout({ children: <Component {...pageProps} />  })}
        </>
    );
};
export default App;