/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { project: {} };

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectDetails(state, actions) {
      state.project = actions.payload
    }
  }
});

export const { setProjectDetails } = projectSlice.actions;

export default projectSlice.reducer;
