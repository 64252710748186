import Image from 'next/image'
import React from 'react'
import Text from '../atoms/Text'
const ViewOnly = ({basePath = process.env.NEXT_PUBLIC_BASE_PATH , className}) => {
  return (
    <div className={`flex ${className}`}>
        <Text className='text-gray-500 text-xs font-normal mr-1'>&#40;</Text>
       <Image src={`${basePath}/images/eye.svg`} alt='eye' width='16' height='16'/>
       <Text className='text-gray-500 text-xs font-normal ml-0.5'>View-only</Text>
       <Text className='text-gray-500 text-xs font-normal ml-1'>&#41;</Text>
    </div>
  )
}

export default ViewOnly