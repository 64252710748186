export function debounce(func, wait, immediate) {
  let timeout;
  return function executedFunction() {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const parseData = (data) => JSON.parse(data);

export const mailToGmail = (value) => `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberInLakhAndCrore = (value) => {
  if (value >= 100000000) {
    const updatedValue = String(value).slice(0, 3);
    const valueToShow = updatedValue[2] === '0'
      ? updatedValue.slice(0, 2)
      : `${updatedValue.slice(0, 2)}.${updatedValue.slice(2, 3)}`;
    return `${valueToShow} Cr`;
  }
  if (value < 100000000 && value >= 10000000) {
    const updatedValue = String(value).slice(0, 2);
    const valueToShow = updatedValue[1] === '0'
      ? updatedValue.slice(0, 1)
      : `${updatedValue.slice(0, 1)}.${updatedValue.slice(1, 2)}`;
    return `${valueToShow} Cr`;
  }
  if (value < 10000000 && value >= 1000000) {
    const updatedValue = String(value).slice(0, 3);
    const valueToShow = updatedValue[2] === '0'
      ? updatedValue.slice(0, 2)
      : `${updatedValue.slice(0, 2)}.${updatedValue.slice(2, 3)}`;
    return `${valueToShow} Lakh`;
  }
  if (value < 1000000 && value >= 100000) {
    const updatedValue = String(value).slice(0, 3);
    const valueToShow = updatedValue[1] === '0'
      ? updatedValue.slice(0, 1)
      : `${updatedValue.slice(0, 1)}.${updatedValue.slice(1, 2)}`;
    return `${valueToShow} Lakh`;
  }
  return getNumberWithComma(value);
};
export const getNumberWithComma = (number) => number.toLocaleString('en-IN');

export const getInitials = (fullName) => {
  const names = fullName?.split(' ');
  return names && names[0].substring(0, 1).toUpperCase();
};
