import React from 'react'
import PropTypes from 'prop-types';

const Images = ({src, alt , className, handleClick, ...properties}) => {
  return (
  <img src={src} alt={alt} className={` ${className}`}  onClick={handleClick} {...properties}/>
  )
}

Images.propTypes = {
  className: PropTypes.string,
  onClick:PropTypes.func,
  alt:PropTypes.string,
  src:PropTypes.string
};

export default Images