import { deleteWithAuth, getWithOutAuth } from '@/services/http.service';
import {
    DELETE_TABLES, GET_TABLES,
    GET_TABLE_DETAILS,
    GET_TABLE_RELATIONS
} from '@/services/url.service';

export const getTables = ( projectId, offset, limit, search ) => getWithOutAuth(GET_TABLES(projectId, offset, limit, search));
export const getTableDetails = ( projectId, tableName ) => getWithOutAuth(GET_TABLE_DETAILS(projectId,tableName));
export const getTableRelations = ( projectId, tableName ) => getWithOutAuth(GET_TABLE_RELATIONS(projectId,tableName));
export const deleteTables = ( projectId ) => deleteWithAuth(DELETE_TABLES(projectId));