import Image from "next/image";
import PropTypes from "prop-types";
const Input = ({
  type,
  placeholder,
  varient,
  className,
  handleClick,
  change,
  onChange,
  value,
  register = () => {},
  name,
  error,
  showErrorIcon=false,
  inputRef,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  ...properties
}) => {
  const inputVarient = {
    normal:
      "py-3 px-[18px] border rounded-lg focus:outline-none text-gray-500 font-normal text-sm",
    password:
      "py-3 px-[18px] border rounded-lg focus:outline-none font-normal text-sm",
    default:
      "py-3 px-[18px] border rounded-lg focus:outline-none font-normal text-xs",
  };
  const inputField = register(name);
  return (
    <>
      <input
        type={type}
        value={value}
        ref={inputRef}
        placeholder={placeholder}
        className={`${inputVarient[varient]} ${className}`}
        onFocus={(e) => handleClick && handleClick(e)}
        onChange={onChange}
        {...inputField}
        {...properties}
      />
      {error && (
        <div className="flex text-red-400 font-normal leading-5 text-xs gap-0.5 mt-0.5">
          {
           showErrorIcon &&  <Image
              src={`${basePath}/images/alert-circle-2.svg`}
              width="10"
              height="10"
              alt="alert-circle"
            />
          }
          {error.message}
        </div>
      )}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.string,
  className: PropTypes.string,
  handleCLick: PropTypes.func,
  change: PropTypes.bool,
  register: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.string,
  basePath: PropTypes.string,
};
export default Input;
