import Image from "next/image";
import PropTypes from "prop-types";
import Input from "../atoms/Input";

const SearchField = ({
  containerClass,
  imgSrc,
  hasFocus,
  setFocus,
  value,
  setValue,
  className,
  searchRef,
  setShowLogout,
  setShowDropDown,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
}) => {
  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
  };

  return (
    <div className={`relative w-[320px] h-8 ${containerClass}`}>
      <Input
        className={`w-full h-full border placeholder:text-gray-500 text-gray-700 pr-10 focus:outline-none font-normal text-sm rounded-md ${
          !hasFocus && !value && "pl-7 border-gray-300 bg-gray-60"
        }
        ${className}`}
        value={value}
        placeholder={`${!hasFocus ? "Search a table...." : ""}`}
        type="text"
        onChange={(e) => handleChange(e)}
        onClick={(e) => {
          e.stopPropagation();
          setFocus(true);
          setShowDropDown(false);
          setShowLogout(false);
        }}
        inputRef = {searchRef}
      />
      {!hasFocus && !value && (
        <span className="absolute left-2 top-[7px] pointer-events-none">
          <Image src={imgSrc} alt="search Icon" height="16" width="16" />
        </span>
      )}
      {value && (
        <span
          className="absolute cursor-pointer top-2 right-2 "
          onClick={() => setValue("")}
        >
          <Image
            src={`${basePath}/images/x.svg`}
            alt="close"
            height="16"
            width="16"
          />
        </span>
      )}
    </div>
  );
};

SearchField.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  hasFocus: PropTypes.bool,
  setFocus: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  classForInput: PropTypes.string,
};
export default SearchField;
