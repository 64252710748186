import React from "react";
const Loader = () => {
  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="sk-chase mt-4">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
