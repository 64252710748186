import Image from "next/image";
import PropTypes from "prop-types";
import Text from "../atoms/Text";
const SearchExpandList = ({
  className,
  tableName,
  handleClick,
  resultContainer,
  index,
  focusedIndex,
  handleSelection,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
}) => {

  return (
    <div
      className={`h-9 hover:bg-gray-50 cursor-pointer px-4 flex gap-1 justify-between items-center group  expand-list ${index===focusedIndex?'bg-gray-50':'bg-white'} ${className}`}
      onClick={(e) =>{handleClick(e, tableName)}}
      ref={index === focusedIndex ? resultContainer : null}
      onMouseDown={()=>handleSelection(index)}
    >
      <div className={`group-hover:w-[70%] ${index===focusedIndex?'w-[70%]':'w-[98%]'}`}>
        <Text className={`group-hover:text-purple-700 text-sm font-normal leading-5 truncate ${index===focusedIndex?'text-purple-700':'text-gray-600'}`}>
          {tableName}
        </Text>
      </div>
      <div className={`items-center group-hover:flex ${index===focusedIndex?'flex':'hidden '}`}>
        <Text className="text-gray-400 text-xxs mr-1">navigate to</Text>
        <Image
          src={`${basePath}/images/undo.svg`}
          alt="navigate"
          height={14}
          width={14}
        />
      </div>
    </div>
  );
};
SearchExpandList.propTypes = {
  className: PropTypes.string,
  tableName: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default SearchExpandList;
