import React from 'react'
import PropTypes from "prop-types";
const TextAvatar = ({className,firstLetter}) => {
  return (
    <div className={`rounded-full bg-primary-900 flex justify-center items-center text-white font-normal ${className}`}>
       {firstLetter}
    </div>
  )
}

TextAvatar.propTypes = {
 className:PropTypes.string,
 firstLetter:PropTypes.string
}
export default TextAvatar