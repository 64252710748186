import App from '@/components/App/App';
import ErrorHandlerWrapper from '@/components/Errors/ErrorHandlerWrapper';
import { eventTrigger } from "@/lib/gtm";
import { getAuth, removeAuth } from '@/services/identity.service';
import { updateLastVisited } from '@/services/user.service';
import axios from 'axios';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { useEffect } from 'react';
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { dumpLogsToAwsCloudWatch } from "services/logger.service";
import nextI18NextConfig from '../next-i18next.config';
import store from '../store';
import '../styles/globals.css';

let persistor = persistStore(store);

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    const auth = getAuth()
    const refreshToken = auth.refreshToken;
    return axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/refresh-token`,
      {
        refreshToken: refreshToken, email: auth.email
      }
    ).then((res) => {
      setAuth({ ...auth, token: res.data.token, refreshToken: res.data.refreshToken })
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    }).catch((err) => {
      removeAuth();
      sessionStorage.clear();
      localStorage.clear();
      //   removeRT()
      if (typeof window !== 'undefined') {
        window.open("/app/login", "_self");
      }
      return Promise.reject(err);
    })
    //    return error
  }
}
);



function NapsesApp({ Component, pageProps, router }) {

  function logError(msg, url, lineNo, columnNo, error) {
    if (!error) return;

    const errorMessage = msg.toLowerCase();
    let errorType = 'BrowserError: ';

    if (errorMessage.includes('script error')) {
      errorType = 'Script Error: ';
    }
    const body = {
      logMessage: errorType + msg,
      logData: {
        url,
        error: { message: error.message, stack: error.stack },
        lineNo,
        columnNo,
        location: {
          host: window.location.host,
          hostname: window.location.hostname,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname
        }
      }
    }
    dumpLogsToAwsCloudWatch(body);
  }
  useEffect(() => {
    window.onerror = logError;
  }, []);

  useEffect(() => {
    const auth = getAuth();
    if (auth) {

      const { projectId } = auth;
      const paths = ["/", "/login", "/404"];
      if (paths.includes(router.pathname)) {
        return router.push(`/db-view/${projectId}`);
      }
    }
    const handleComplete = () => {
      return true;
    };
    const handleError = () => true;

    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  }, [router.events]);

  useEffect(() => {
    const auth = getAuth();
    if (auth && sessionStorage.getItem('sessionUserId') === null) {
      sessionStorage.setItem('sessionUserId', auth.id)
      eventTrigger('CE_RETURNING_USERS', {
        user: { id: auth.id, name: auth.fullName }
      });
      updateVisitedUser()
    }
  }, [])

  const updateVisitedUser = async () => {
    await updateLastVisited()
  }

  return (
    <>
      <Head>
        <title>DB Daddy</title>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Head>
      <ErrorHandlerWrapper>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App Component={Component} pageProps={pageProps} router={router} />
          </PersistGate>
        </Provider>
      </ErrorHandlerWrapper>
    </>
  );
}

export default appWithTranslation(NapsesApp, nextI18NextConfig);
