import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => cookies.get('AUTH');

export const setAuth = (authObject) => {
    const monthInMillis = 365 * 3 * 86400000;
    cookies.set('AUTH', JSON.stringify(authObject), { path: '/', expires: new Date(Date.now() + monthInMillis) });
    return authObject;
  }

export const removeAuth = () => {
    cookies.remove('AUTH', { path: '/' });
};

export const isInRole = (role, user) => user.roles && user.roles.includes(role);

export const isAuthenticated = (user) => user != null && user.token;

export const isUnauthorizedRequest = (auth) => !auth || !isAuthenticated(JSON.parse(auth));
