import PropTypes from "prop-types";
import { ReactFlowProvider } from "reactflow";
import Navbar from "../../components/navbar/Navbar";

const Layout = ({ children }) => {
  return (
    <ReactFlowProvider>
      <div className=" w-full min-h-screen ">
        <Navbar />
        <div className="main-content w-full">{children}</div>
      </div>
    </ReactFlowProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
