import { getWithAuth, postWithAuth, postWithOutAuth } from '@/services/http.service';
import {
  GET_USER_FOR_AUTHENTICATION, RESEND_OTP, SIGN_IN_WITH_EMAIL, UPDATE_LAST_VISITED, UPDATE_USER, VERIFY_OTP
} from '@/services/url.service';

export const authenticateUser = (token) => getWithAuth(GET_USER_FOR_AUTHENTICATION, token);
export const signInWithEmail = (email) => postWithOutAuth(SIGN_IN_WITH_EMAIL, { email })
export const resendOtp = (email) => postWithOutAuth(RESEND_OTP, { email })
export const verifyOtp = (details) => postWithOutAuth(VERIFY_OTP, details)
export const updateUser = (details) => postWithAuth(UPDATE_USER, details);
export const updateLastVisited = () => postWithAuth(UPDATE_LAST_VISITED)

