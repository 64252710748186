import PropTypes from 'prop-types';
const Anchor = ({ href, children, className, id , target}) => {
  return (
    <a id={id} href={href} target={target} className={`${className}`}>{children}</a>
  )
}
Anchor.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}
export default Anchor