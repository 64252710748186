import Button from 'atoms/Button';

export default function GlobalErrorPage({ resetErrorBoundary }) {
  return (
    <div className='flex flex-col max-w-82 gap-4 justify-center items-center mx-auto mt-48'>
        <p className='text-primary-main text-lg'>Something went wrong</p>
        <Button type='mediumContained' onClick={resetErrorBoundary} >Try again</Button>
    </div>
  )
}
