/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { showLoader: false };

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showLoader(state) {
            state.showLoader = true;
        },
        hideLoader(state) {
            state.showLoader = false;
        }
    }
});

export const loaderActions = loaderSlice.actions;

export default loaderSlice.reducer;
