import PropTypes from "prop-types";
import Anchor from "../atoms/Anchor";
import Image from "../atoms/Images";
import List from "../atoms/List";
import Tooltip from "../atoms/Tooltip";
import SubMenu from "./SubMenu";
const Menu = ({
  className,
  listOfMenu,
  rounded = "rounded-lg",
  border = "border border-gray-200",
  shadow = "shadow-box",
  iconWidth = "12",
  iconHeight = "12",
  classForSubmenu,
}) => {
  return (
    <div className={`bg-white ${rounded} ${shadow} ${border} ${className}`}>
      <ul>
        {listOfMenu &&
          listOfMenu.filter(({ showOption }) => showOption).map(
            ({
              id,
              behaviour,
              anchorId,
              className,
              name,
              target,
              href,
              classForList,
              showIcon = false,
              basePath,
              imgSrc,
              imgAlt,
              hoverImage,
              toolTip,
              classForImage,
              _imgSrc,
              _imgAlt,
              _hoverImage,
              _showIcon = false,
              _classForImage,
              subMenu,
            }) => {
              return (
                <List
                  key={id}
                  className={`bg-white text-gray-700 hover:bg-primary-50  hover:text-purple-700 font-normal cursor-pointer leading-5 ${classForList}`}
                  onClick={behaviour}
                >
                  {showIcon && (
                    <span className={`flex items-center ${classForImage}`}>
                      <Image
                        src={`${basePath}${imgSrc}`}
                        alt={imgAlt}
                        width={14}
                        height={14}
                        className={"block"}
                      />
                      <Image
                        src={`${basePath}${hoverImage}`}
                        alt={imgAlt}
                        width={14}
                        height={14}
                        className={"hidden"}
                      />
                    </span>
                  )}

                  <Anchor
                    id={anchorId}
                    className={className}
                    target={target && target}
                    href={href && href}
                  >
                    {name}
                  </Anchor>

                  {_showIcon && (
                    <span className={`flex items-center ${_classForImage}`}>
                      <Image
                        src={`${basePath}${_imgSrc}`}
                        alt={_imgAlt}
                        width={14}
                        height={14}
                        className={"block"}
                      />
                      <Image
                        src={`${basePath}${_hoverImage}`}
                        alt={_imgAlt}
                        width={14}
                        height={14}
                        className={"hidden"}
                      />
                    </span>
                  )}
                  {toolTip && (
                    <Tooltip
                      classForToolTip="p-2"
                      textColor="text-gray-200"
                      position="invisible group-hover:visible absolute top-0 left-[328px]"
                      toolTipArrowPosition="-left-1.5 top-1/2 -translate-y-1/2"
                      toolTipContent={toolTip}
                    />
                  )}
                  {subMenu && (
                    <SubMenu
                      classForSubmenu={classForSubmenu}
                      SubMenu={subMenu}
                    />
                  )}
                </List>
              );
            }
          )}
      </ul>
    </div>
  );
};
Menu.propTypes = {
  className: PropTypes.string,
  listOfMenu: PropTypes.array,
};

export default Menu;