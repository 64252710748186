import Modal from '../../molecules/Modal'
import React, { useRef,useState} from 'react'
import Heading from '../../atoms/Heading'
import Image from 'next/image'
import Text from '../../atoms/Text'
import FileUpload from '../../molecules/FileUpload'
import ConnectToDb from '../ConnectToDb/ConnectToDb'

const ImportFileModel = ({basePath = process.env.NEXT_PUBLIC_BASE_PATH,setShowFileImport ,handleFileSelect,projectId,tables,setShowIncorrectDetails,setErrorMessage,showIncorrectDetails,setIsLoading}) => {
   const wrapperref =useRef('null')
   const [active,setActive]=useState('postgres'); 

  const handleDragOver=(event)=>{
    event.preventDefault(); 
    let file =document.getElementById('filedragondrop')
    file.classList.remove('bg-white');
    file.classList.add('bg-gray-50');
  }
  const handleDrapLeave=(e)=>{
    let file =document.getElementById('filedragondrop')
    file.classList.remove('bg-gray-50');
    file.classList.add('bg-white');
  }

  const handleOnDrop=(e)=>{
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
       handleFileSelect(e);
    }
  }
  const handleActiev =(name) => {
       setActive(name)
  }


  return (
    <Modal classForParent='py-[5%]' classForChildren='max-w-[628px] shadow-md' border='border-0' borderRadius='rounded-md'>
      <div className='flex px-6 py-4 justify-between items-center border-b border-gray-200'>
        <Heading type='h5' className='text-gray-700 font-semibold leading-7'>Import</Heading>
         <span className='cursor-pointer flex items-center' onClick={()=>setShowFileImport(false)}><Image src={`${basePath}/images/Close-3.svg`} alt='close' width='32' height='32'/></span>
      </div>
      <div className='flex h-full overflow-hidden'>
       <div className='w-[36%] border-r border-gray-200'>
          <div className={`py-2.5 pl-4 pr-3 cursor-pointer ${active ==='postgres' ? 'text-primary-900 bg-primary-100 border-l-2 border-primary-400':'text-gray-700 bg-white border-b border-b-gray-200 border-l-2 border-l-white'}`} onClick={()=>handleActiev('postgres')}>
            <Text className='text-sm font-normal'>Generate From SQL structure (PostgreSQL only)</Text>
          </div>
          <div className={`py-2.5 pl-4 pr-3 cursor-pointer ${active ==='db' ? 'text-primary-900 bg-primary-100 border-l-2 border-primary-400':'text-gray-700 bg-white border-b border-b-gray-200 border-l-2 border-l-white'}`}  onClick={()=>handleActiev('db')}>
            <Text className='text-sm font-normal'>Generate From Database Connection</Text>
          </div>
          <div className={`py-2.5 pl-4 pr-3 cursor-pointer ${active === 'dbml' ? 'text-primary-900 bg-primary-100 border-l-2 border-primary-400':'text-gray-700 bg-white border-b border-b-gray-200 border-l-2 border-l-white'}`}  onClick={()=>handleActiev('dbml')}>
            <Text className='text-sm font-normal'>Generate From Existing DBML definition</Text>
          </div>
       </div>
       <div className='w-[74%] py-2 px-[30px] pb-[30px]'>
              <Text className='text-gray-700 text-base font-medium text-center mb-1'>{active ==='postgres' && 'Generate From SQL structure (PostgreSQL only)' || active ==='dbml' && 'Generate From Existing DBML definition' || active ==='db' && 'Generate From Database Connection' } </Text>
             {(active ==='postgres' ||  active==='dbml') && <FileUpload  basePath={basePath} handleOnDrop={handleOnDrop} handleDragOver={handleDragOver} handleFileSelect={handleFileSelect} handleDrapLeave={handleDrapLeave} className=''/>}
             {( active ==='db') &&  <ConnectToDb basePath={basePath} className='mt-3' projectId={projectId} tables={tables} setShowIncorrectDetails={setShowIncorrectDetails} setErrorMessage={setErrorMessage} showIncorrectDetails={showIncorrectDetails} setIsLoading={setIsLoading} setShowFileImport={setShowFileImport} />}      
        </div>
      </div>
    </Modal>
  )
}

export default ImportFileModel