/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { showEditor: true };

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    toggleEditor(state) {
      state.showEditor = !state.showEditor
    }

  }
});

export const { toggleEditor } = editorSlice.actions;

export default editorSlice.reducer;
